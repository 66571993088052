html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.cursor-pointer{
  cursor: pointer;
}

.menu-item {
  font-size: 14px !important;
  line-height: 1.8 !important; 
}

.submenu-item {
  font-size: 14px !important; 
  line-height: 2.1 !important; 
}

.active-menu {
  color: #229952 !important; 
}

.active-submenu {
  color: #488348 !important; 
  background-color: #488348 !important;
}
