body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
}

.hero {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
    position: relative;
    z-index: 1;
}

.hero img {
    max-width: 165px;
    height: auto;
}

.form-block {
    background: white;
    padding: 40px;
    max-width: 400px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.form-block h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #399100;
}

.form-block input[type="text"],
.form-block input[type="username"] {
    border: none;
    border-radius: 4px;
    height: 54px;
    background: #efefef;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    color: #777B82;
    box-sizing: border-box;
}
.form-block input[type="text"],
.form-block input[type="password"] {
    border: none;
    border-radius: 4px;
    height: 54px;
    background: #efefef;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    color: #777B82;
    box-sizing: border-box;
}

.form-block button[type="submit"] {
    display: block;
    width: 100%;
    height: 54px;
    padding: 0;
    background: #399100;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
}

.form-block button[type="submit"]:hover {
    background-color: #2D7200;
}
